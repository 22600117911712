.service-header {
    background-color: #f8f9fa;
    padding: 40px 0;
  }
  
  .service-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .service-header p {
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  .service-section {
    padding: 40px 0;
  }
  
  .service-card {
    border: 1px solid #dee2e6;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .service-card:hover {
    transform: scale(1.05);
  }
  
  .service-image img {
    border-bottom: 2px solid #661245;
  }
  
  .service-content {
    padding: 20px;
  }
  
  .service-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #661245; /* Adjusting color for visibility */
  }
  
  .service-content p {
    color: #495057;
    margin-bottom: 15px;
  }
  
  .btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
    cursor: pointer; /* Change cursor to pointer for buttons */
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  