.faq-section {
    background-color: #f0f4f8;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.faq-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.faq-title {
    font-size: 36px;
    color: #2c3e50;
    margin-bottom: 30px;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.faq-item {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.faq-item.active {
    background-color: #e3f2fd;
    border-left: 5px solid #2196f3;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-question h4 {
    margin: 0;
    font-size: 18px;
    color: #34495e;
}

.faq-icon {
    font-size: 24px;
    color: #2196f3;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
    line-height: 1.5;
}
