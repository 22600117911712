
/** place-section **/

.place-section{
  position: relative;
  padding: 25px;
}

.place-block-one .inner-box{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 30px;
}

.place-block-one .inner-box .image-box{
  position: relative;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}
/* 
.place-block-one .inner-box .image-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  opacity: 0.5;
} */

/* .place-block-one .inner-box .image-box img{
  width: 100%;
  border-radius: 5px;
  transition: all 500ms ease;
}

.place-block-one .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.place-block-one .inner-box .text{
  position: absolute;
  left: 0px;
  bottom: 30px;
  width: 100%;
  text-align: center;
  z-index: 2;
  font-size: 40px;
  line-height: 30px;
  font-family: var(--secondary-font);
  color: #fff;
} */


/** place-style-two **/

.place-style-two{
  position: relative;
}

.place-style-two .place-list{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.place-style-two .sec-title a{
  position: absolute;
  right: 0px;
  bottom: 10px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: var(--title-color);
}

.place-style-two .sec-title a i{
  position: relative;
  margin-left: 6px;
}

.place-style-two .sec-title a:hover{

}

.place-block-two .inner-box{
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

/* .place-block-two .inner-box .image-box{
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 22px;
  overflow: hidden;
  overflow: hidden;
}

.place-block-two .inner-box .image-box:before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, .2);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 1;
}

.place-block-two .inner-box:hover .image-box:before {
  -webkit-animation: circle .95s;
  animation: circle .95s;
}

.place-block-two .inner-box .image-box img{
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.place-block-two .inner-box:hover .image-box img{
  transform: scale(1.05);
} */

.place-block-two .inner-box h4{
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
}

.place-block-two .inner-box h4 a{
  display: inline-block;
  color: var(--title-color);
}

.place-block-two .inner-box h4 a:hover{

}

.place-style-two .pattern-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 627px;
  height: 400px;
  background-repeat: no-repeat;
}

.place-style-two.home-3{
  background: #FAF6F2;
}

.place-style-two.home-3 .pattern-layer{
  top: 40px;
}

.place-style-two .pattern-layer-2{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.03;
}

.place-section .pattern-layer .pattern-1{
  position: absolute;
  left: 0px;
  bottom: 290px;
  width: 285px;
  height: 323px;
  background-repeat: no-repeat;
}

.place-section .pattern-layer .pattern-2{
  position: absolute;
  right: 0px;
  bottom: 110px;
  width: 192px;
  height: 349px;
  opacity: 0.1;
  background-repeat: no-repeat;
}


/** rtl-css **/

.rtl .place-style-two .sec-title a{
  right: inherit;
  left: 0px;
}

/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .place-section{
    padding: 70px 0px 40px 0px;
  }

  .place-style-two .place-list{
    display: block;
  }

  .place-style-two{
    padding: 40px 0px;
  }

  .place-style-two.home-3{
    padding-top: 70px;
  }

  .place-style-two.destination-page-3{
    padding-top: 70px;
  }
}

@media only screen and (max-width: 599px){
  .place-style-two .sec-title a{
    position: relative;
    top: 0px;
    margin-top: 10px;
  }

  .place-style-two .sec-title{
    text-align: center;
  }
}

@media only screen and (max-width: 499px){

}









































