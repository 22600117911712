.page-header {
    background-color: #f8f9fa;
    padding: 40px 0;
  }
  
  .page-header h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .page-header h2 {
    font-size: 2.5rem;
  }
  
  .our-network {
    padding: 40px 0;
  }
  
  .network-title {
    text-align: center;
    font-size: 2rem;
    color: #681347;
    margin-bottom: 30px;
  }
  
  .network-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .network-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    width: 300px;
  }
  
  .network-card:hover {
    transform: scale(1.05);
  }
  
  .network-image {
    width: 100%;
    height: auto;
  }
  
  .network-card-content {
    padding: 20px;
    background-color: #681347;
    color: white;
    text-align: center;
  }
  
  .network-card-content h3 {
    margin: 10px 0;
  }
  
  .network-card-content p {
    font-size: 0.9rem;
  }
  .network-image {
    width: 100%;
    height: auto;
    border-bottom: 3px solid #53CAF5; 
  }