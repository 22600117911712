
/** testimonial-section **/

.testimonial-section{
  position: relative;
  background: #57B5E1;
}

.testimonial-block-one .inner-box{
  position: relative;
  display: block;
  background: #661247;
  color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.04);
}

.testimonial-block-one .inner-box .rating{
  position: absolute;
  display: flex;
  align-items: center;
  top: 40px;
  right: 40px;
}

.testimonial-block-one .inner-box .rating li{
  margin-right: 7px;
}

.testimonial-block-one .inner-box .rating li:last-child{
  margin: 0px !important;
}

.testimonial-block-one .inner-box .author-box{
  position: relative;
  display: block;
  padding: 2px 0px 2px 70px;
  margin-bottom: 35px;
}

.testimonial-block-one .inner-box .author-box .author-thumb{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.testimonial-block-one .inner-box .author-box .author-thumb img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-one .inner-box .author-box h3{
  display: block;
  font-size: 24px;
  line-height: 30px;
}

.testimonial-block-one .inner-box .author-box .designation{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
}

.testimonial-section .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-section .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-section .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-section .dots-style-one .owl-dots{
  position: relative;
  display: block;
  text-align: center;
  margin-top: 40px;
}

.testimonial-section .owl-nav{
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -30px;
  width: 100%;
}

.testimonial-section .owl-nav .owl-prev{
  position: absolute;
  left: -100px;
}

.testimonial-section .owl-nav .owl-next{
  position: absolute;
  right: -100px;
}

.testimonial-section .pattern-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


/** testimonial-style-two **/

.testimonial-style-two{
  position: relative;
  background: #F8FFF6;
}

.testimonial-style-two .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-style-two .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-style-two .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-style-two .pattern-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.03;
}

.testimonial-block-two{
  margin-top: 45px;
}

.testimonial-block-two .inner-box{
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.04);
  padding: 0px 40px 40px 40px;
}

.testimonial-block-two .inner-box .thumb-box{
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -45px;
  margin-bottom: 16px;
}

.testimonial-block-two .inner-box .thumb-box img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-two .inner-box h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0px;
}

.testimonial-block-two .inner-box .designation{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  /* color: #6A6A6A; */
  margin-bottom: 10px;
}

.testimonial-block-two .inner-box .rating{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.testimonial-block-two .inner-box .rating li{
  margin: 0px 3.5px;
}

.testimonial-style-two .owl-theme .owl-dots{
  margin-top: 50px;
}

.testimonial-style-two .owl-theme .owl-dots .owl-dot span{
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0px 7.5px;
  cursor: pointer;
  opacity: 0.3;
  transition: all 500ms ease;
}

.testimonial-style-two .owl-theme .owl-dots .owl-dot.active span,
.testimonial-style-two .owl-theme .owl-dots .owl-dot span:hover{
  opacity: 1;
  transform: scale(1.9);
}

.testimonial-section.testimonial-page-one{
  background: #fff;
}


/** rtl-css **/

.rtl .testimonial-block-one .inner-box{
  text-align: right;
}

.rtl .testimonial-block-one .inner-box .rating{
  right: inherit;
  left: 40px;
}

.rtl .testimonial-block-one .inner-box .author-box{
  padding-left: 0px;
  padding-right: 70px;
}

.rtl .testimonial-block-one .inner-box .author-box .author-thumb{
  left: inherit;
  right: 0px;
}

/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .testimonial-section .dots-style-one .owl-dots{
    display: none;
  }

  .testimonial-section{
    padding: 70px 0px;
  }

  .testimonial-style-two .owl-theme .owl-dots{
    display: none;
  }

  .testimonial-style-two{
    padding: 70px 0px;
  }

  .offers-section.testimonial-page-one{
    padding: 0px 0px 40px 0px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){
  .testimonial-block-one .inner-box .rating{
    position: relative;
    right: 0px;
    top: 0px;
    margin-bottom: 20px;
  }

  .rtl .testimonial-block-one .inner-box .rating{
    left: 0px;
  }

  .testimonial-block-one .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .testimonial-block-two .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }
}









































