/** news-section **/

.news-section{
  position: relative;
}

.news-block-one .inner-box{
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.news-block-one .inner-box .image-box{
  position: relative;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

.news-block-one .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-one .inner-box .lower-content{
  position: relative;
  display: block;
  padding-top: 30px;
}

.news-block-one .inner-box .lower-content .post-info{
  position: relative;
  display: flex;
  align-items: center;
}

.news-block-one .inner-box .lower-content .post-info li{
  position: relative;
  padding-left: 29px;
  margin-right: 30px;
  font-size: 16px;
  line-height: 26px;
}

.news-block-one .inner-box .lower-content .post-info li:first-child{
  padding-left: 0px;
}

.news-block-one .inner-box .lower-content .post-info li:last-child{
  margin: 0px !important;
}

.news-block-one .inner-box .lower-content .post-info li i{
  position: absolute;
  left: 0px;
  top: 2px;
  font-size: 20px;
}

.news-block-one .inner-box .lower-content .post-info li a{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  border-radius: 5px;
  padding: 3px 13px;
  text-align: center;
  background: rgba(255, 107, 0, 0.05);
  border: 1px solid rgba(255, 107, 0, 0.20);
}

.news-block-one .inner-box .lower-content .post-info li a:hover{
  color: #fff;
}

.news-block-one .inner-box .lower-content h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
}

.news-block-one .inner-box .lower-content h3 a{
  display: inline-block;
  color: var(--title-color);
}

.news-block-one .inner-box .lower-content h3 a:hover{

}

.home-2 .news-block-one .inner-box .lower-content .post-info li a{
  background: rgba(140, 207, 45, 0.05);
  border-color: rgba(140, 207, 45, 0.20);
}

.home-2 .news-block-one .inner-box .lower-content .post-info li a:hover{
  color: #fff;
}

.blog-grid .news-block-one .inner-box{
  margin-bottom: 50px;
}

.news-block-one .inner-box .lower-content h2{
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 15px;
}

.news-block-one .inner-box .lower-content h2 a{
  display: inline-block;
  color: var(--title-color);
}

.news-block-one .inner-box .lower-content h2 a:hover{

}

.sidebar-page-container .news-block-one .inner-box{
  margin-bottom: 45px;
}


/** rtl-css **/

.rtl .news-block-one .inner-box .lower-content .post-info li{
  margin-right: 0px;
  margin-left: 30px;
}

/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .news-section{
    padding: 70px 0px 35px 0px;
  }

  .blog-grid{
    padding: 70px 0px;
  }
}

@media only screen and (max-width: 599px){
  .news-block-one .inner-box .lower-content h2{
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 499px){

}









































