.category-section {
  overflow: hidden; /* Optional: to ensure content doesn't overflow */
  background-color: #57B5E1;
  padding: 20px;
}

.category-block-one {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center elements horizontally */
  text-align: center; /* Center text */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.inner-box {
  padding: 10px; /* Adjust as necessary */
}

.image-box img  {
  width: 100%; 
  /* height: 250px;  */
  display: block; /* Prevents bottom space caused by inline-block */
}

h4 {
  margin-top: 10px; /* Space between image and title */
  margin-bottom: 0; /* Ensures no gap below title */
}
