.car-section {
    background-color: rgb(241, 241, 241);
    padding: 40px 0;
}

.car-item {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    padding: 20px; /* Increased padding for better spacing */
    display: flex;
    align-items: center; /* Center content vertically */
}

.car-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.car-image img {
    width: 150px; /* Set fixed width for the dummy image */
    height: auto;
    border-radius: 8px; /* Rounded corners for image */
}

.car-info {
    flex-grow: 1; /* Allow info to take remaining space */
    padding-left: 15px; /* Space between image and text */
}

.car-title {
    font-size: 1.5rem; /* Increase title size */
    font-weight: 600; /* Bold title */
    margin-bottom: 10px; /* Space below title */
}

.car-attributes {
    list-style: none;
    padding: 0;
}

.car-attributes li {
    margin-bottom: 5px;
}

.car-attributes span {
    font-weight: 500; /* Bold attribute names */
}

.car-price {
    padding-left: 20px; /* Space for the price button */
}

.btn-main {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
}

.btn-main:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Subtle lift on hover */
}
