.booking-header {
  background-color: #f7f9fc; /* Light background */
  padding: 40px 0;
}

.booking-header h2 {
  color: #681448; /* Primary color */
  font-size: 2.5rem;
}

.booking-header p {
  color: #555;
  font-size: 1.2rem;
  margin-top: 10px;
}

.section-title {
  color: #333;
  font-size: 2rem;
  margin: 40px 0 20px;
  text-align: center;
}

.our-booking {
  padding: 40px 0;
}

.booking-card {
  border: 1px solid #681448; /* Blue border */
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff; /* White background */
}

.booking-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.card-image {
  position: relative;
}

.card-img-top {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 20px;
  text-align: center;
}

.content-border {
  border-top: 2px solid #681448; /* Border between image and content */
  padding-top: 15px; /* Padding for spacing */
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #681448; /* Primary color for title */
  margin: 15px 0;
}

.card-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px; /* Space below description */
}

.btn-primary {
  background-color: #681448;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #681448;
}
