.terms-section {
    background-color: #f9f9f9;
    /* padding: 40px 20px; */
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
}

.terms-title {
    font-size: 36px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
}

.terms-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.terms-content h3 {
    color: #681348;
}

.terms-content p {
    margin-bottom: 15px;
    line-height: 1.6;
}

.highlight {
    color: #681348; /* Unique color for bold text */
}

.contact-info {
    margin-top: 30px;
    text-align: center;
}

.contact-info h2 {
    color: #6C154A;
}

.contact-info ul {
    list-style-type: none;
    padding: 0;
}

.contact-info a {
    color: #2196f3;
    text-decoration: none;
}


.image-container {
    text-align: center;
    margin: 20px 0;
}

.terms-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
}
