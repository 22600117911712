/* Career.css */

.page-header {
    position: relative;
    background: #f8f9fa;
    padding: 60px 0;
    text-align: center;
}

.page-header-shape {
    /* background: url('path/to/your/background/image.jpg') no-repeat center center/cover; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0.1;
}

.page-header-info h4 {
    font-size: 24px;
    color: #343a40;
}

.page-header-info h2 {
    font-size: 36px;
    color: #007bff;
    margin: 10px 0 0;
}

.section-title {
    font-size: 28px;
    color: #343a40;
    margin-bottom: 20px;
}

.section-subtitle {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 40px;
}

.career-item {
    background-color: #ffffff;
    border-radius: 10px ;
    border: 3px solid  #6A1449;
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
    padding: 20px; /* Added padding for better layout */
}

.career-item:hover {
    transform: translateY(-5px);
    background-color: #6A1449; 
    color: #ffffff; /* Change text color on hover */
}

.career-icon {
    color: #007bff;
}

.career-title {
    font-size: 20px;
    margin: 15px 0;
}

.career-title a {
    text-decoration: none;
    color: inherit; /* Inherit color from the parent */
}

.career-title a:hover {
    text-decoration: underline;
    color: #ffffff; /* Change link color on hover */
}

.career-item p {
    color: #495057;
    line-height: 1.6;
}

.career-item:hover p {
    color: #ffffff; /* Change paragraph text color on hover */
}

@media (max-width: 768px) {
    .career-item {
        margin-bottom: 20px;
    }

    .career-title {
        font-size: 18px;
    }
}
.news-card-items {
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.news-card-items:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}
.news-image img {
    width: 100%;
    height: auto;
}
.news-content {
    padding: 15px;
}
.news-content h3 a {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
}
.news-content h3 a:hover {
    text-decoration: underline;
}
.news-content p {
    color: #666;
    font-size: 0.95rem;
    line-height: 1.5;
}
