/* Contact Form Section Styling */
.contact-form-section {
    padding: 60px 15px;
    background-color: #661247;
  }

  .contact-form-container {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .contact-form-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    text-align: center;
    color: #333;
  }
  
  .contact-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .contact-form-group {
    flex: 1;
    min-width: calc(50% - 15px);
    margin-bottom: 15px;
  }
  
  .contact-form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .contact-form-input,
  .contact-form-select,
  .contact-form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .contact-form-input:focus,
  .contact-form-select:focus,
  .contact-form-textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .contact-form-textarea {
    height: 120px;
    resize: vertical;
  }
  
  .contact-form-submit {
    text-align: center;
  }
  
  .contact-form-submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form-submit-button:hover {
    background-color: #0056b3;
  }
  
  .contact-form-recaptcha {
    margin: 20px 0;
  }
  .default-btn{
    background-color: #661247;
    color: white;
    padding: 15px;


  }