#why-choose-section {
    max-width: 800px; 
    margin: auto; 
    padding: 20px; 
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); 
    overflow: hidden; 
  }
  
  .slider-item {
    padding: 40px; 
    background-color: #6C164C; 
    border-radius: 12px; 
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transitions */
    position: relative; /* Position for pseudo-elements */
    /* opacity: 0.8;  */
    /* height: 400px;  */
    display: flex; /* Flexbox for centering content */
    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Center items vertically */
  }
  
  .slider-item:hover {
    transform: scale(1.05); /* Scale up on hover */
    opacity: 1; /* Full opacity on hover */
  }
  
  .slider-heading {
    font-size: 28px; /* Larger font size */
    /* color: #00796b;  */
    margin-bottom: 15px; /* Space below heading */
    /* text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);  */
  }
  
  .slider-description {
    font-size: 18px; /* Adjust font size */
    /* color: #004d40;  */
    text-align: center; /* Center text for description */
  }
  
  .slick-dots {
    bottom: -40px; /* Adjust position of dots */
  }
  
  .slick-dots li button:before {
    color: #007bff; /* Dot color */
  }
  
  .slick-dots li.slick-active button:before {
    color: #0056b3; /* Active dot color */
  }
  .slider-item .slider-heading {
    font-size: 28px;
    /* color: #00796b !important;  */
    margin-bottom: 15px;
}
