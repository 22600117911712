
.rtl {
  direction: rtl; 
}

.ltr {
  direction: ltr; 
}

.rtl .switcher{
  right: 60px;
  left: inherit;
}

.rtl .switcher .switch_menu{
  left: inherit;
  right: 0px;
}

.rtl .owl-carousel{
  direction: ltr;
}

.rtl .scroll-to-top .scroll-bar{
  margin-right: 0px;
  margin-left: 10px;
}

.rtl .main-menu .navigation > li{
  float: right;
}

.rtl .main-menu .navigation > li:first-child{
  margin-left: 15px !important;
  margin-right: 0px;
}

.rtl .main-menu .navigation > li:last-child{
  margin-right: 15px !important;
  margin-left: 0px;
}

.rtl .main-menu .navigation > li > ul > li > a, 
.rtl .main-menu .navigation > li > .megamenu li > a{
  text-align: right;
}

.rtl .main-menu .navigation li.dropdown .megamenu li h4{
  text-align: right;
}

.rtl .main-menu .navigation > li > ul > li > ul > li > a{
  text-align: right;
}

.rtl .main-menu .navigation > li > ul > li.dropdown > a:after{
  right: inherit;
  left: 20px;
  content: "\f104";
}

.rtl .mobile-menu{
  text-align: right;
}

.rtl .mobile-menu .nav-logo{
  text-align: right;
}

.rtl .mobile-menu .navigation li > a:before{
  left: inherit;
  right: 0px;
}

.rtl .mobile-menu .navigation li.dropdown .dropdown-btn{
  right: inherit;
  left: 6px;
}

.rtl .mobile-menu .navigation li.dropdown .dropdown-btn.open{
  transform: rotate(-90deg);
}

.rtl .scroll-to-top{
  right: inherit;
  left: 0px;
}

.rtl .header-top .top-inner .social-links li{
  margin-right: 0px;
  margin-left: 30px;
}

.rtl .header-upper .upper-inner .right-column .info-list li{
  margin-right: 0px;
  margin-left: 50px;
}

.rtl .header-upper .upper-inner .right-column .info-list{
  margin-right: 0px;
  margin-left: 50px;
}

.rtl .main-header .menu-right-content .select-box{
  margin-right: 0px;
  margin-left: 30px;
}

.rtl .nice-select .option{
  text-align: right;
}

.rtl .nice-select{
  text-align: right !important;
}

.rtl .footer-bottom .social-links li{
  margin-right: 0px;
  margin-left: 20px;
}

.rtl .main-header .search-box-outer{
  margin-right: 0px;
  margin-left: 25px;
}

.rtl .main-header .language-box{
  margin-right: 0px;
  margin-left: 25px;
}





























