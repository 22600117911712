.place-section {
    padding: 60px 0;
    background-color: #f9f9fb;
}

.sec-title {
    text-align: center;
    margin-bottom: 40px;
}

.sec-title .sub-title {
    font-size: 1.2rem;
    color: #661247;
    font-weight: 600;
    margin-bottom: 8px;
    text-transform: uppercase;
    display: block;
}

.sec-title h2 {
    font-size: 2.5rem;
    color: #333;
    margin: 0;
    font-weight: 700;
}

.place-block {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.place-block-one {
    width: 100%;
    max-width: 340px;
    background-color: #661247;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.place-block-one:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.image-box {
    overflow: hidden;
    width: 100%;
    /* height: 220px; */
    border-bottom: 2px solid #661247;
}

.image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.place-block-one:hover .image-box img {
    transform: scale(1.1);
}

.text {
    display: block;
    padding: 15px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #661247;
    text-align: center;
    background-color: #f9f9fb;
}
