.page-header {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .page-header-shape {
    background-color: #ff7700;
    height: 200px;
    width: 100%;
  }
  
  .gallery-section {
    padding: 20px 0;
  }
  
  .gallery-card {
    overflow: hidden;
    transition: transform 0.3s;
  }
  
  .gallery-card:hover {
    transform: scale(0.95); /* Slightly decrease size on hover */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .modal-prev,
  .modal-next {
    font-size: 2rem;
    cursor: pointer;
  }
  