.unique-section {
    background-color: #00cf68;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center all content */
    align-items: flex-start;
}

.col-info {
    flex: 0 0 30%; /* Adjusted for two content blocks */
    max-width: 30%;
    margin-bottom: 20px;
    padding: 0 10px; /* Add some padding */
}

.info-item {
    margin-bottom: 15px;
}

.info-title {
    font-size: 1.2em;
    color: #343a40;
    display: flex;
    align-items: center;
}

.info-title i {
    color: #681348;
    margin-right: 10px;
}

.info-description {
    padding-right: 15px;
    color: #ffffff;
}

.col-image {
    flex: 0 0 40%; /* Center image larger */
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.experience-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
