
/** tours-section **/

.tours-section{
  position: relative;
  background: #F6FBFF;
  text-align: center;
}

.tours-section .pattern-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.tours-section .upper-box{
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.tours-block-one .inner-box{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  border: 3px solid #57B5E1;
  /* background: #681348; */
  /* background: #0997C9 ; */
  /* color: white; */
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.tours-block-one .inner-box .image-box{
  position: relative;
  display: block;
}

.tours-block-one .inner-box .image-box .image{
  position: relative;
  display: block;
  overflow: hidden;
}
.abhi{
  border-bottom: 3px solid #57B5E1;

}
.tours-block-one .inner-box .image-box .image img{
  width: 100%;
  transition: all 500ms ease;
}

.tours-block-one .inner-box:hover .image-box .image img{
  transform: scale(1.05);
}

.tours-block-one .inner-box .image-box .rating{
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 21px;
  line-height: 21px;
  background: #fff;
  text-align: center;
  border-radius: 50px;
  z-index: 1;
  font-size: 14px;
  color: var(--title-color);
}

.tours-block-one .inner-box .image-box .rating img{
  position: relative;
  top: -3px;
}

.tours-block-one .inner-box .lower-content{
  position: relative;
  display: block;
  padding: 24px 30px 31px 30px;
}

.tours-block-one .inner-box .lower-content h6{
  display: block;
  font-size: 12px;
  line-height: 20px;
  /* color: #AAAAAA; */
  text-transform: uppercase;
  margin-bottom: 6px;
}

.tours-block-one .inner-box .lower-content h4{
  display: block;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 4px;
}

.tours-block-one .inner-box .lower-content h4 a{
  display: inline-block;
  color: var(--title-color);
}

.tours-block-one .inner-box .lower-content h4 a:hover{

}

.tours-block-one .inner-box .lower-content h5{
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 14px;
}

.tours-block-one .inner-box .lower-content h5 span{
  font-size: 14px;
  font-family: var(--text-font);
  font-weight: 400;
  color: var(--title-color);
}

.tours-block-one .inner-box .lower-content .day-time{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--title-color);
  padding-left: 21px;
  margin-bottom: 15px;
}

.tours-block-one .inner-box .lower-content .day-time i{
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 16px;
}

.tours-block-one .inner-box .lower-content .link a{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: var(--title-color);
  padding-right: 25px;
}

.tours-block-one .inner-box .lower-content .link a i{
  position: absolute;
  top: 5px;
  right: 0px;
}
.text-infoo{
  color: #000000;
}
.texter{
  color: #661247;
}
.tours-section .tab-btns{
  position: relative;
  display: flex;
  align-items: center;
}

.tours-section .tab-btns li{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: var(--title-color);
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 20px;
  margin-right: 10px;
  transition: all 500ms ease;
}

.tours-section .tab-btns li:last-child{
  margin: 0px !important;
}

.tours-section .tab-btns li.active-btn,
.tours-section .tab-btns li:hover{
  color: #fff;
}

.tours-section.alternat-2{
  background: #fff;
}

.tours-section .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.tours-section .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
  visibility: hidden;
}

.tours-section .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
  visibility: visible;
}

.tours-section .owl-nav{
  position: absolute;
  top: -127px;
  right: 0px;
}

.tours-section .owl-nav .owl-prev{
  margin-right: 15px;
}

.tours-block-one .inner-box .lower-content .rating{
  position: absolute;
  top: 30px;
  right: 30px;
  width: 60px;
  height: 21px;
  line-height: 21px;
  background: #F3F3F3;
  text-align: center;
  border-radius: 50px;
  z-index: 1;
  font-size: 14px;
  color: var(--title-color);
}

.tours-block-one .inner-box .lower-content .rating img{
  position: relative;
  display: inline-block;
  top: -3px;
}

.tours-section .three-item-carousel{
  padding-bottom: 90px;
  border-bottom: 1px solid #D9D9D9;
}

.tour-list-content{
  position: relative;
  display: block;
}

.item-shorting{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
}

.item-shorting .text p{
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: var(--title-color);
}

.item-shorting .nice-select{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: var(--title-color);
  padding: 4px 60px 4px 20px;
  border-radius: 3px;
  border: 1px solid rgba(229, 229, 229, 1);
}

.item-shorting .nice-select:before{
  display: none;
}

.item-shorting .nice-select:after{
  position: absolute;
  content: '\e90b';
  font-family: 'icomoon';
  font-size: 10px;
  top: 5px;
  right: 20px;
}

.item-shorting .right-column{
  position: relative;
  display: flex;
  align-items: center;
}

.tours-block-one .inner-box .image-box .offer-box{
  position: absolute;
  top: 20px;
  right: 88px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: 25px;
  padding: 1px 10px;
}

.tours-section.alternat-2 .tours-block-one .inner-box .image-box .offer-box{
  right: 20px;
}

.tours-section .pattern-layer .pattern-1{
  position: absolute;
  left: 0px;
  top: 400px;
  width: 285px;
  height: 323px;
  background-repeat: no-repeat;
}

.tours-section .pattern-layer .pattern-2{
  position: absolute;
  right: 0px;
  top: 570px;
  width: 192px;
  height: 349px;
  opacity: 0.05;
  background-repeat: no-repeat;
}

.tour-page-five-section .pattern-layer .pattern-1{
  position: absolute;
  left: 0px;
  top: 400px;
  width: 285px;
  height: 323px;
  background-repeat: no-repeat;
}

.tour-page-five-section .pattern-layer .pattern-2{
  position: absolute;
  right: 0px;
  top: 570px;
  width: 192px;
  height: 349px;
  opacity: 0.05;
  background-repeat: no-repeat;
}

.tour-page-five-section .item-shorting .left-column{
  position: relative;
  display: flex;
  align-items: center;
}

.tour-page-five-section .item-shorting .left-column .select-box{
  margin-right: 30px;
}

.tour-page-five-section .item-shorting .left-column .select-box:last-child{
  margin: 0px !important;
}

.item-shorting .select-box{
  min-height: 40px;
}

.item-shorting .nice-select .list{
  min-width: 180px;
  left: inherit !important;
}

.tours-section.testimonial-page-two .inner-container{
  padding-top: 120px;
  border-top: 1px solid rgba(217, 217, 217, 1);
}

.tours-section.testimonial-page-two .three-item-carousel{
  padding-bottom: 0px;
  border-bottom: none;
}



/** rtl-css **/

.rtl .tours-section .tab-btns li{
  margin-right: 0px;
  margin-left: 10px;
}

.rtl .tours-block-one .inner-box .lower-content .day-time{
  padding-left: 0px;
  padding-right: 21px;
}

.rtl .tours-block-one .inner-box .lower-content .day-time i{
  left: inherit;
  right: 0px;
}

.rtl .tour-page-five-section .item-shorting .left-column .select-box{
  margin-right: 0px;
  margin-left: 30px;
}

.rtl .tour-page-five-section .item-shorting .text{
  margin-right: 0px;
  margin-left: 30px;
  
}

/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .tours-section .upper-box{
    display: block;
    text-align: center;
  }

  .tours-section .upper-box .sec-title{
    margin-bottom: 30px;

  }

  .tours-section .tab-btns{
    justify-content: center;
  }

  .tour-page-five-section .item-shorting{
    display: block;
  }

  .tour-page-five-section .item-shorting .left-column{
    justify-content: center;
    margin-bottom: 30px;
  }

  .tour-page-five-section .item-shorting .right-column{
    justify-content: center;
  }
}

@media only screen and (max-width: 767px){
 .tours-section .tab-btns{
  display: block;
 }

 .tours-section .tab-btns li{
  float: left;
  margin-bottom: 10px;
 }

 .rtl .tours-section .tab-btns li{
  float: right;
  margin-right: 0px;
  margin-left: 10px;
 }

 .tours-section .upper-box{
  text-align: left;
 }

 .rtl .tours-section .upper-box{
  text-align: right;
 }

 .tours-section{
  padding: 70px 0px 40px 0px;
 }

 .tours-section .three-item-carousel{
  padding-bottom: 40px;
 }

 .tour-page-five-section .item-shorting .left-column{
  display: block;
 }

 .tour-page-five-section .item-shorting .left-column .select-box{
  margin: 0px 0px 30px 0px !important;
 }

 .tour-page-five-section{
  padding: 70px 0px;
 }

 .tours-section .owl-nav{
  display: none;
 }

 .tours-section.testimonial-page-two .inner-container{
  padding-top: 70px;
 }

}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){

}








































