 
/* Css For Travic */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two
5. Main Slider/style-one/style-two
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Testimonial Section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section
39. Google Map


**********************************************/



/*** 

====================================================================
  Reset
====================================================================

 ***/
* {
  margin:0px;
  padding:0px;
  border:none;
  outline:none;
}


/*** 

====================================================================
  Global Settings
====================================================================

 ***/

*{
  /* border: 1px solid red; */
}
:root{
  --theme-color: #6A144A;
  --secondary-color: #6A144A;
  /* --text-color: #6A6A6A; */
  --title-color: #111111;
  --text-font: 'Inter', sans-serif;
  --title-font: 'Urbanist', sans-serif;
  --secondary-font: 'Seaweed Script', cursive;
}
.testi{
  color: white;
}
.user{
  color: #43B5E8;
}
.whitt{
  color: #4FC2EA;
}
.whit{
  color: white;
}
.darkcolor{
  color: #661447;
}
.whycolor{
  color: #661447;
}
.Border-key{
  border: 1px solid #691449;
}
.Coloo{
  color: #54D5F0;
}
.borderrr{
  border: 3px solid #691449;
  padding: 20px;
}


@media (min-width: 768px) {
  .logoo{
    width: 40%;
    height: 40%;
  }
}
.borderr{
  border: 5px solid  #661247;
}
body {
  font-size:16px;
  color: var(--text-color);
  line-height:28px;
  font-weight:400;
  background:#ffffff;
  font-family: var(--text-font);
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center top;
  -webkit-font-smoothing: antialiased;
}
.bgg{
  background-color: #661247;
}
  @media (min-width:1200px) {
  .container {
    max-width: 1200px;
    padding: 0px 15px;
  }
}

.large-container{
  max-width: 1550px;
  padding: 0px 15px;
  margin: 0 auto;
}

.container-fluid{
  padding: 0px;
}

.auto-container{
  position:static;
  max-width:1320px;
  padding:0px 15px;
  margin:0 auto;
}

.small-container{
  max-width:680px;
  margin:0 auto;
}

.boxed_wrapper{
  position: relative;
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}


a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

a:hover{
  text-decoration:none;
  outline:none;
}

input,button,select,textarea{
  font-family: var(--text-font);
  font-weight: 400;
  font-size: 18px;
  background: transparent;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}

input{
  transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}

p{
  position: relative;
  font-family: var(--text-font);
  /* color: var(--text-color); */
  font-weight: 400;
  margin: 0px;
  transition: all 500ms ease;
}

h1,h2,h3,h4,h5,h6{
  position:relative;
  font-family: var(--title-font);
  font-weight: 700;
  color: var(--title-color);
  margin: 0px;
  transition: all 500ms ease;
}

.tabs-box .tab{
  position:relative;
  display:none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab{
  display:block;  
}

.tabs-box .tab{
  transform:scale(0.9,0.9) translateY(0px);
}

.tabs-box .tab.active-tab{
  transform:scale(1) translateY(0px);
}

/* AnimaciÃ³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .handle-preloader .animation-preloader .txt-loading .letters-loading {font-size: 36px; letter-spacing: 6px;}
}


.centred{
  text-align: center;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}


figure{
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.row{
  --bs-gutter-x: 30px;
}

/** button **/


.theme-btn{
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  padding: 10px 36px;
  font-family: var(--text-font);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.theme-btn:hover{
  color: #fff;
}



.theme-btn:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.theme-btn:hover:before{
  transform: scale(1,1);
}




.theme-btn.btn-three{
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 17px 34px;
}



.parallax-bg{
  position: absolute;
  left: 0px;
  top: -30%;
  width: 100%;
  height: calc(100% + 30%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

















.about-section {
  background-color: #f9f9f9;
  padding: 100px 0;
}

.sec-title {
  text-align: center;
  margin-bottom: 50px;
}

.sec-title .sub-title {
  font-size: 16px;
  color: #777;
}

.sec-title h2 {
  font-size: 32px;
  color: #333;
}
.bluecolor{
  color: #6C144B;
}
.content-box {
  padding: 20px;
}

.content-box h3 {
  font-size: 24px;
  color: #444;
  margin-bottom: 15px;
}

.content-box p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

.image-box {
  text-align: center;
}

.image-box img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6C154A;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

.btn:hover {
  background-color: #6C154A;
}


@media (max-width: 768px) {
  .navbar-nav .nav-item {
    border-bottom: 1px solid #6C164A;
  }

}


@media (max-width:400px){
  .carousel-inner img{
      height: 200px;
  }
}

.news-details-section {
  padding: 60px 0;
  background-color: #661347;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.de-item-list h3 {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
}

.form-border {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 30px;
  background-color: #fff; /* White background for the form */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
}

.form-control:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.btn-main {
  background-color: #53C7E0; /* Green background for submit button */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px; /* Add padding to button */
}

.btn-main:hover {
  background-color: #53C7E0; /* Darker green on hover */
}

.success, .error {
  display: none; /* Hidden by default */
  margin-top: 20px;
  text-align: center;
}

.success {
  color: #28a745; /* Green color for success message */
}

.error {
  color: #dc3545; /* Red color for error message */
}

@media (max-width: 768px) {
  .col-8 {
      flex: 0 0 100%;
      max-width: 100%;
  }
}



.he a{
  display: block;
  color: white;
  text-decoration: none;
}
/* #about {
  background-image: url(../../public/img/hero.jpg);
  background-size: cover; 
  background-position: center;
  height: 100vh;
} */
#about {
  /* border: 5px solid red; */
  /* background-image: url(../../public/img/hero.jpg); */
  background: 
  linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), /* Adjust colors and opacity as needed */
  url(../../public/img/hero.jpg);
  /* background-size: cover;  */
  background-position: center; /* Centers the image */
  /* width: 100%; */
  height: 40vh; /* Sets the height to 100% of the viewport height */
  display: flex; /* Optional: If you want to center content */
  align-items: center;
  justify-content: center; 
  color: white;
}

.case{
  color: white;
}
/** styled-pagination */

.pagination{
  position: relative;
  display: block;
}

.pagination li{
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.pagination li a{
  position: relative;
  display: inline-block;
  font-size: 18px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-weight: 500;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.04);
  background: #fff;
  border-radius: 50%;
  text-align: center;
  color: var(--title-color);
  z-index: 1;
  transition: all 500ms ease;
}

.pagination li a:hover,
.pagination li a.current{
  color: #fff;
}

.sec-pad{
  padding: 142px 0px 150px 0px;
}

.mr-0{
  margin: 0px !important;
}

/** scroll-to-top **/

.scroll-to-top{
  position: fixed;
  right: 0px;
  bottom: 100px;
  transform: rotate(90deg);
  z-index: 99;
}

.scroll-to-top .visible {
  visibility: visible!important;
  opacity: 1!important;
}

.scroll-to-top .scroll-top-inner {
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top .scroll-top-inner{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
  -o-transition: all cubic-bezier(.4,0,.2,1) .4s;
  transition: all cubic-bezier(.4,0,.2,1) .4s;
}

.scroll-to-top .scroll-bar {
  width: 50px;
  height: 2px;
  margin-right: 10px;
  position: relative;
}

.scroll-to-top .scroll-bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4244f;
  opacity: .3;
}

.scroll-to-top .scroll-bar .bar-inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: currentColor;
}

.scroll-to-top .scroll-bar-text{
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--theme-color);
  transition: all 500ms ease;
}

.light-home .scroll-to-top .scroll-bar-text{
  color: var(--secondary-color);
}

.scroll-to-top .scroll-bar-text:hover{
  transform: scale(1.1);
}



@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes slide{
  from { background-position: 0 0; }
    to { background-position: 1920px 0; }
}

@-webkit-keyframes slide{
  from { background-position: 0 0; }
    to { background-position: 1920px 0; }
}



.sec-title{
  position: relative;
  display: block;
}

.sec-title .sub-title{
  position: relative;
  display: block;
  font-size: 32px;
  line-height: 24px;
  font-family: var(--secondary-font);
  margin-bottom: 20px;
}

.sec-title h2{
  position: relative;
  display: block;
  font-size: 42px;
  line-height: 50px;
  font-weight: 700;
  margin: 0px;
}

.sec-title.light h2{
  color: #fff !important;
}




/*** 

====================================================================
                        Home-Page-One
====================================================================

***/


/** main-header **/

.main-header{
  position:relative;
  left:0px;
  top:0px;
  right: 0px;
  z-index:999;
  width:100%;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.sticky-header{
  position:fixed;
  opacity:0;
  visibility:hidden;
  left:0px;
  top:0px;
  width:100%;
  z-index:0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.fixed-header .sticky-header{
  z-index:999;
  opacity:1;
  visibility:visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


/** header-top **/

.header-top{
  position: relative;
  width: 100%;
  background: linear-gradient(90deg, #343434 0.47%, #000000 100%);
  padding: 11px 0px;
}

.header-top .top-inner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-top .top-inner p{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 20px;
  padding-left: 29px;
  color: #F7F5F5;
}

.header-top .top-inner p i{
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 18px;
}

.header-top .top-inner .social-links{
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .top-inner .social-links li{
  margin-right: 30px;
}

.header-top .top-inner .social-links li:last-child{
  margin: 0px !important;
}

.header-top .top-inner .social-links li span{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.header-top .top-inner .social-links li a{
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #fff;
}


/** header-upper **/

.header-upper{
  position: relative;
  width: 100%;
  background: #fff;
}

.header-upper .upper-inner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-upper .upper-inner .logo-box{
  position: relative;
  padding: 25px 0px;
}

.header-upper .upper-inner .right-column{
  position: relative;
  display: flex;
  align-items: center;
}

.header-upper .upper-inner .right-column .info-list{
  position: relative;
  display: flex;
  align-items: center;
}

.header-upper .upper-inner .right-column .info-list li{
  position: relative;
  display: inline-block;
  margin-right: 50px;
  padding-left: 22px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--title-color);
}

.header-upper .upper-inner .right-column .info-list li:last-child{
  margin: 0px !important;
}

.header-upper .upper-inner .right-column .info-list li i{
  position: absolute;
  left: 0px;
  top: 1px;
  font-size: 18px;
}

.header-upper .upper-inner .right-column .info-list li a{
  display: inline-block;
  color: var(--title-color);
}

.header-upper .upper-inner .right-column .info-list li a:hover{

}

.header-upper .upper-inner .right-column .search-inner{
  position: relative;
  width: 300px;
}

.header-upper .upper-inner .right-column .search-inner .form-group{
  position: relative;
  margin: 0px;
}

.header-upper .upper-inner .right-column .search-inner .form-group input[type='search']{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  font-size: 16px;
  color: var(--text-color);
  padding: 10px 70px 10px 20px;
  transition: all 500ms ease;
}

.header-upper .upper-inner .right-column .search-inner .form-group input:focus{

}

.header-upper .upper-inner .right-column .search-inner .form-group button[type='submit']{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.main-header .outer-box{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header .header-lower .outer-box{
  background: rgba(255, 107, 0, 0.07);
  border-radius: 5px 5px 0px 0px;
  padding: 0px 40px 0px 35px;
}

.main-header .menu-right-content{
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0px;
}

.main-header .menu-right-content .nice-select{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  color: var(--title-color);
  text-transform: uppercase;
  padding-right: 17px;
}

.nice-select:before{
  position: absolute;
  content: '';
  background: #000;
  width: 10px;
  height: 5px;
  top: 12px;
  right: 0px;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%, 0% 0%);
}

.main-header .menu-right-content .login-box a{
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  color: var(--title-color);
  padding-left: 21px;
}

.main-header .menu-right-content .login-box a i{
  position: absolute;
  left: 0px;
  top: 6px;
  font-size: 18px;
}

.main-header .menu-right-content .login-box a:hover{

}




/** main-menu **/

.main-menu{
  float: left;
}

.main-menu .navbar-collapse{
  padding:0px;
  display:block !important;
}

.main-menu .navigation{
  margin:0px;
}

.main-menu .navigation > li{
  position:inherit;
  float:left;
  z-index:2;
  margin: 0px 26px;
  -webkit-transition:all 300ms ease;
  -moz-transition:all 300ms ease;
  -ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;
  transition:all 300ms ease;
}

.main-menu .navigation > li:last-child{
  margin-right:0px !important;
}

.main-menu .navigation > li:first-child{
  margin-left: 0px !important;
}

.main-menu .navigation > li > a{
  position:relative;
  display:block;
  text-align:center;
  font-size:14px;
  line-height:30px;
  padding: 15px 0px;
  font-weight:500;
  font-family: var(--text-font);
  opacity:1;
  color: var(--title-color);
  text-transform: uppercase;
  z-index:1;
  -webkit-transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  transition:all 500ms ease;
}

.light-home .main-menu .navigation > li > a{
  color: #fff;
}

.main-menu .navigation > li.current > a,
.main-menu .navigation > li:hover > a{
  
}

.main-menu .navigation > li > ul,
.main-menu .navigation > li > .megamenu{
  position:absolute;
  left: inherit;
  top:100%;
  width:250px;
  margin-top: 15px;
  z-index:100;
  display:none;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul.from-right{
  left:auto;
  right:0px;  
}

.main-menu .navigation > li > ul > li{
  position:relative;
  width:100%;
}

.main-menu .navigation > li > ul > li > a,
.main-menu .navigation > li > .megamenu li > a{
  position:relative;
  display:block;
  padding: 10px 25px;
  line-height:30px;
  font-weight:500;
  font-size:14px;
  text-transform:capitalize;
  font-family: var(--text-font);
  color:#fff;
  text-align: left;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > a{
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

.main-menu .navigation > li > .megamenu li > a{
  padding-left: 0px;
}

.main-menu .navigation > li > .megamenu h4{
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.main-menu .navigation > li > ul > li > a:hover,
.main-menu .navigation > li > .megamenu li > a:hover{
  padding-left: 35px;
}

.main-menu .navigation > li > ul > li:last-child > a,
.main-menu .navigation > li > .megamenu li:last-child > a{
  border-bottom: none;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
  font-family: 'Font Awesome 6 Free';
  content: "\f105";
  position:absolute;
  right:20px;
  top:10px;
  display:block;
  line-height:24px;
  font-size:16px;
  font-weight:800;
  text-align:center;
  z-index:5;  
}

.main-menu .navigation > li > ul > li > ul{
  position:absolute;
  left:100%;
  top:0%;
  margin-top: 15px;
  width:250px;
  z-index:100;
  display:none;
  border-radius: 0px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul.from-right{
  left:auto;
  right:0px;  
}

.main-menu .navigation > li > ul > li > ul > li{
  position:relative;
  width:100%;
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
  border-bottom:none; 
}

.main-menu .navigation > li > ul > li > ul > li > a{
  position:relative;
  display:block;
  padding:10px 25px;
  line-height:24px;
  font-weight:500;
  font-size:14px;
  text-transform:capitalize;
  font-family: var(--text-font);
  color:#fff;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  text-align: left;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:last-child > a{
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover{
  padding-left: 35px;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after{
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";
  position:absolute;
  right:20px;
  top:12px;
  display:block;
  line-height:24px;
  font-size:16px;
  font-weight:900;
  z-index:5;  
}

.main-menu .navigation > li.dropdown:hover > ul,
.main-menu .navigation > li.dropdown:hover > .megamenu{
  visibility:visible;
  opacity:1;
  margin-top: 0px;
  top: 100%;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
  visibility:visible;
  opacity:1;
  top: 0%; 
  margin-top: 0px;
}

.main-menu .navigation li.dropdown .dropdown-btn{
  position:absolute;
  right:-32px;
  top:66px;
  width:34px;
  height:30px;
  text-align:center;
  font-size:18px;
  line-height:26px;
  color:#3b3b3b;
  cursor:pointer;
  display: none;
  z-index:5;
  transition: all 500ms ease;
}

.main-menu .navigation li.current.dropdown .dropdown-btn,
.main-menu .navigation li:hover .dropdown-btn{
  
}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn{
  display: none;
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  background: var(--theme-color);
  display: none;
}

.mobile-menu .nav-logo img{
  max-width: 160px;
}

.menu-area .mobile-nav-toggler .icon-bar{
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child{
  margin-bottom: 0px;
}


/** megamenu-style **/

.main-menu .navigation > li.dropdown > .megamenu{
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li h4{
  margin-bottom: 10px;
}

.sticky-header .main-menu .navigation > li > a{
  padding-top: 25px;
  padding-bottom: 25px;
  color: #000;
}

.sticky-header .main-menu .navigation > li.dropdown > a:before{
  top: 27px;
}

.sticky-header  .main-menu:before{
  top: 15px;
}


/** mobile-menu **/

.nav-outer .mobile-nav-toggler{
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color:#3786ff;
  display: none;
}

.mobile-menu{
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right:30px;
  max-width:100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 900ms ease;
}

.mobile-menu .navbar-collapse{
  display:block !important; 
}

.mobile-menu .nav-logo{
  position:relative;
  padding:50px 25px;
  text-align:left;  
  padding-bottom: 100px;
}

.mobile-menu-visible{
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop{
  position: fixed;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 900ms ease;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
  opacity: 0.70;
  visibility: visible;
  right: 100%;
  -webkit-transition: all .8s ease-out 0s;
  -o-transition: all .8s ease-out 0s
}

.mobile-menu .menu-box{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #141417;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 900ms ease !important;
}

.mobile-menu-visible .mobile-menu .menu-box{
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn{
  position: absolute;
  right: 25px;
  top: 10px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition:all 0.9s ease;
  -moz-transition:all 0.9s ease;
  -ms-transition:all 0.9s ease;
  -o-transition:all 0.9s ease;
  transition:all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn{
  -webkit-transform:rotate(360deg);
  -ms-transform:rotate(360deg);
  transform:rotate(360deg);
}

.mobile-menu .close-btn:hover{
  -webkit-transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  transform:rotate(90deg);
}

.mobile-menu .navigation{
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li{
  position: relative;
  display: block;
  border-top: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation:last-child{
  border-bottom: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation li > ul > li:first-child{
  border-top: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation li > a{
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
}

.mobile-menu .navigation li ul li > a{
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li > a:before{
  content:'';
  position:absolute;
  left:0;
  top:0;
  height:0;
  border-left:5px solid #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
}

.mobile-menu .navigation li.current > a:before{
  height:100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
  position:absolute;
  right:6px;
  top:6px;
  width:32px;
  height:32px;
  text-align:center;
  font-size:16px;
  line-height:32px;
  color:#ffffff;
  background:rgba(255,255,255,0.10);
  cursor:pointer;
  border-radius:2px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
  z-index:5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open{
  color: #ffffff;
  -webkit-transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  transform:rotate(90deg);  
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul,
.mobile-menu .navigation > li.dropdown > .megamenu{
  display: none;
}

.mobile-menu .social-links{
  position:relative;
  padding:0px 25px;
}

.mobile-menu .social-links li{
  position:relative;
  display:inline-block;
  margin:0px 10px 10px;
}

.mobile-menu .social-links li a{
  position:relative;
  line-height:32px;
  font-size:16px;
  color:#ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
}

.mobile-menu .social-links li a:hover{
  
}

div#mCSB_1_container{
  top: 0px !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 120px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255,255,255,0.80);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a{
  color: rgba(255,255,255,0.80);
}

.mobile-menu .contact-info ul li a:hover{
  
}

.mobile-menu .contact-info ul li:last-child{
  margin-bottom: 0px;
}

.main-header .outer-box{
  position: relative;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav{
  display: none !important;
}

.owl-nav button{
  background: transparent;
}

.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.float-bob-x{
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}


/** rtl-switcher **/

.demo-rtl{
  position: fixed;
  top: 390px;
  left: 10px;
  z-index: 9999;
}

button.rtl{
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms ease;
}

.light-home button.rtl{
  background: var(--secondary-color);
}

.demo-ltr{
  position: fixed;
  top: 390px;
  left: auto;
  right: 10px;
  z-index: 9999;
}

button.ltr {
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 1.0);
  transition: all 500ms ease;
}

.light-home button.ltr{
  background: var(--secondary-color);
}

.boxed_wrapper.ltr .demo-rtl{
  display: block;
}

.boxed_wrapper.ltr .demo-ltr{
  display: none;
}

.boxed_wrapper.rtl .demo-rtl{
  display: none;
}

.boxed_wrapper.rtl .demo-ltr{
  display: block;
}

.dots-style-one .owl-dots .owl-dot span{
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  margin: 0px 5px;
  background: transparent !important;
  transition: all 500ms ease;
}

.dots-style-one .owl-dots .owl-dot.active span,
.dots-style-one .owl-dots .owl-dot span:hover{

}

.dots-style-one .owl-dots .owl-dot span:before{
  position: absolute;
  content: '';
  width: 14px;
  height: 14px;
  border: 2px solid #303030;
  border-radius: 50%;
  left: 2px;
  top: 2px;
}

.dots-style-one .owl-dots .owl-dot.active span:before,
.dots-style-one .owl-dots .owl-dot span:hover:before{
  width: 12px;
  height: 12px;
  left: 3px;
  top: 3px;
}

.border-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255,255,255,.5);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  animation: squares 2.9s linear 0s infinite;
  -webkit-animation: squares 2.9s linear 0s infinite;
  -ms-animation: squares 2.9s linear 0s infinite;
  -o-animation: squares 2.9s linear 0s infinite;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  opacity: 0;
}

.border-animation.border-2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}

.border-animation.border-3 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

.nav-style-one .owl-nav button{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: var(--title-color);
  box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav button:hover{
  color: #fff;
}


/** main-footer **/

.main-footer{
  position: relative;
  background: #191919;
}

.main-footer .pattern-layer{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 560px;
  background-repeat: repeat-x;
  background-position: bottom center;
  animation: slide 60s linear infinite;
  -webkit-animation: slide 60s linear infinite;
}

.main-footer .widget-section{
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.main-footer .logo-widget{
  position: relative;
  max-width: 330px;
}

.main-footer p,
.main-footer a{
  color: #fff;
}

.main-footer a:hover{

}

.main-footer .logo-widget .footer-logo{
  margin-bottom: 19px;
}

.main-footer .logo-widget p{
  margin-bottom: 30px;
}

.main-footer .logo-widget .info-list li{
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
}

.main-footer .logo-widget .info-list li:last-child{
  margin-bottom: 0px;
}

.main-footer .widget-title{
  position: relative;
  display: block;
  margin-bottom: 25px;
}

.main-footer .widget-title h3{
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
}

.main-footer .links-widget .links-list li{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 42px;
}

.footer-bottom{
  position: relative;
  background: #191919;
  width: 100%;
  padding: 24px 0px;
}

.footer-bottom .bottom-inner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-bottom .bottom-inner .copyright p a:hover{
  text-decoration: underline;
}

.footer-bottom .social-links{
  position: relative;
  display: flex;
  align-items: center;
}

.footer-bottom .social-links li{
  margin-right: 20px;
}

.footer-bottom .social-links li:last-child{
  margin: 0px !important;
}

.footer-bottom .social-links li a{
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.60);
}

.footer-bottom .social-links li a:hover{

}


/** header-style-two **/

.header-style-two{
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
}

.header-style-two .header-lower .outer-box{
  background: #fff;
  border-radius: 0px;
  padding: 0px;
}

.header-style-two .header-lower .logo-box{
  padding: 25px 0px;
}

.header-style-two .main-menu .navigation > li > a{
  padding-top: 35px;
  padding-bottom: 35px;
}

.main-header .search-box-outer{
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--title-color);
  cursor: pointer;
  top: 3px;
  transition: all 500ms ease;
}

.main-header .search-box-outer:hover{

}

.main-header .language-box{
  position: relative;
  display: inline-block;
  padding-right: 16px;
  cursor: pointer;
  z-index: 2;
}

.main-header .language-box:before{
  position: absolute;
  content: '';
  background: #000;
  width: 10px;
  height: 5px;
  top: 12px;
  right: 0px;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%, 0% 0%);
}

.main-header .language-box .icon-box{
  position: relative;
  display: inline-block;
  border-radius: 50%;
}

.main-header .language-box .icon-box img{
  width: 100%;
  border-radius: 50%;
}

.main-header .language-box .language-dropdown{
  position: absolute;
  left: 0px;
  top: 40px;
  width: 100px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.main-header .language-box:hover .language-dropdown{
  top: 30px;
  opacity: 1;
  visibility: visible;
}

.main-header .language-box .language-dropdown li{
  position: relative;
  display: block;
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.main-header .language-box .language-dropdown li:last-child{
  border-bottom: none;
}

.main-header .language-box .language-dropdown li button{
  position: relative;
  display: block;
  font-size: 16px;
  color: var(--title-color);
  cursor: pointer;
  transition: all 500ms ease;
}

/*** Search Popup ***/

.search-popup {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.80);
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.search-popup .popup-inner{
  width: 100%;
  background: #fff;
  height: 100%;
}

.search-popup .upper-box{
  position: relative;
  padding: 70px 70px;
  z-index: 99;
}

.search-popup .upper-box .logo-box{
  max-width: 182px;
}

.search-popup .overlay-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
}

.search-popup .close-search {
  position: relative;
  font-size: 25px;
  color: #141417;
  cursor: pointer;
  z-index: 5;
  top: 11px;
  transition: all 500ms ease;
}

.search-popup .close-search:hover {
  color: red;
}

.search-popup .search-form {
  position: relative;
  width: 100%;
  padding: 100px 0px 250px 0px;
}

.search-popup .search-form .form-group{
  position: relative;
  margin: 0px;
}

.search-popup .search-form fieldset input[type="search"] {
  position: relative;
  height: 90px;
  padding: 20px 0px;
  background: #ffffff;
  line-height: 30px;
  font-size: 20px;
  color: #808080;
  font-family: 'Poppins', sans-serif;
  border: none;
  font-weight: 400;
  border-radius: 0px;
  padding-right: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.search-popup .search-form fieldset button[type="submit"] {
  position: absolute;
  top: 30px;
  right: 0px;
  font-size: 25px;
  color: #141417;
  cursor: pointer;
  transition: all 500ms ease;
}

.search-popup .search-form fieldset input[type="search"]:focus{
  border-color: #141417;
}

.search-popup .form-control:focus{
  box-shadow: none !important;
}

@-webkit-keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
}
@keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
}


.pb_0{
  padding-bottom: 0px !important;
}

.white-bg{
  background: #fff !important;
}

.light-footer{
  background: #fff;
}

.light-footer p, 
.light-footer a{
  /* color: #6A6A6A; */
}

.light-footer .logo-widget .info-list li a{
  color: var(--title-color);
}

.light-footer .logo-widget .info-list li a:hover{

}

.light-footer a:hover{

}

.light-footer .widget-title h3{
  color: var(--title-color);
}

.light-footer .widget-section{
  border-color: rgba(0, 0, 0, 0.10);
}

.light-footer .footer-bottom{
  background: #fff;
}

.light-footer .footer-bottom .social-links li a{
  /* color: #6A6A6A; */
}

.light-footer .footer-bottom .social-links li a:hover{

}

.sidebar-page-container{
  position: relative;
}

.sidebar-page-container .pattern-layer .pattern-1{
  position: absolute;
  left: 0px;
  top: 400px;
  width: 285px;
  height: 323px;
  background-repeat: no-repeat;
}

.sidebar-page-container .pattern-layer .pattern-2{
  position: absolute;
  right: 0px;
  top: 570px;
  width: 192px;
  height: 349px;
  opacity: 0.05;
  background-repeat: no-repeat;
}

.accordion-box .block .acc-content{
  display:none;
}

.accordion-box .block .acc-content.current{
  display:block;  
}

.pt_0{
  padding-top: 0px !important;
}



.contact-map-section{
  position: relative;
}

.contact-map-section .map-inner{
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 520px;
}

.contact-map-section .map-inner iframe{
  position: relative;
  width: 100%;
  height: 520px;
}

































